<!-- eslint-disable no-unused-vars -->
<script setup>
import Layout from '../../layouts/main.vue'
import PageHeader from '@/components/page-header'
import franchiseInvoice from '../company/franchiseInvoice'
import { callApi } from '../../../msal/msalApi';
import { reactive, onMounted, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router'
// eslint-disable-next-line no-unused-vars
import Swal from 'sweetalert2'

import { Store, useStore } from "vuex";

const querys = useRoute().query

const store = useStore()


const currentDate = new Date(); // Obtiene la fecha y hora actuales.

const currentYear = currentDate.getFullYear(); // Obtiene el año actual (por ejemplo, 2023).
const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // Obtiene el mes actual (0-11) y lo formatea con dos dígitos (por ejemplo, '08' para agosto).
const currentDay = String(currentDate.getDate()).padStart(2, '0'); // Obtiene el día del mes actual y lo formatea con dos dígitos (por ejemplo, '01' para el primer día).




let data = reactive({
    loadData: false,
    url: '',
    email: '',
    selectDate: `${currentYear}-${currentMonth}-${currentDay}`,
    invoiceDate: '',
    count: 0,
    totalAmount: 0,
    dataBilling: [],
    availableInvoices: [],
    franchiseInvoiceSummaries: [],

    currentBilling: [],
    list:[]
});

async function getPracticeGroup() {
    data.loadData = true
    data.url = `${process.env.VUE_APP_WLT_API}Associate/PracticeGroupMembersPaged?email=${store.getters.getEmail}&sortByList[0].Name=FranchiseName&sortByList[0].Direction=Asc&sortByList[1].Name=LastName&sortByList[1].Direction=Desc`;
    // eslint-disable-next-line no-undef
    const userData = `${process.env.VUE_APP_WLT_API}Client/InfoGet?email=${data.email}`;
    const billings = `${process.env.VUE_APP_WLT_API}Billing/MasterInvoice/${data.selectDate || querys.date}`;


    try {

        const [response, response2, billing] = await Promise.all([
            callApi(data.url, store.getters.getToken),
            callApi(userData, store.getters.getToken),
            callApi(billings, store.getters.getToken),
        ]);

        data.dataBilling = billing
        data.availableInvoices = billing.availableInvoiceDates
        data.franchiseInvoiceSummaries = billing.franchiseInvoiceSummaries
        data.invoiceDate = formatDate(billing.invoiceDate)
        data.count = clientCount(data.franchiseInvoiceSummaries)
        data.totalAmount = totalAmount(data.franchiseInvoiceSummaries)
        data.list = data.availableInvoices


        data.loadData = false
        data.selectDate = billing.invoiceDate
    } catch (error) {
        console.error(error);
    }

}
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Meses son 0-indexados, así que suma 1 y agrega un 0 al principio si es necesario
    const day = date.getDate().toString().padStart(2, '0'); // Agrega un 0 al principio si es necesario

    return `${year}-${month}-${day}`;
};


function clientCount(counter) {
    let sum = 0
    counter.forEach(count => {
        sum = (count.clientCount + sum)

    });

    return sum
}


function totalAmount(amount) {
    let sum = 0
    amount.forEach(count => {
        sum = (count.rateSubTotal + sum)

    });

    return sum.toFixed(2)
}


function filterDate() {
    console.log(formatDate(data.selectDate))
    getPracticeGroup()
}
onBeforeMount(async () => {
    const currentBilling = `${process.env.VUE_APP_WLT_API}Associate/CurrentMemberInfoGet`

    const [response] = await Promise.all([
        callApi(currentBilling, store.getters.getToken)
    ]);
    data.selectDate = querys.date || data.selectDate

    data.currentBilling = response
})
onMounted(async () => {
    getPracticeGroup();
});
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
    </Layout>



    <div class="container  fs-5 text" v-if="data.currentBilling.franchiseMembership.length < 1">

        <div class="spinner-overlay text-center mt-5" v-if="data.loadData">
            <div class="spinner-border text-primary  custom-spinner" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div>Loading...</div>
        </div>

        <div class="row" v-if="!data.loadData">
            <div class="col">
                <p class="fw-bold">Invoice Date</p>
                <div class="col-3">



                    <select class="form-select" v-model="data.selectDate" @change="filterDate">
                        <option :value="date" v-for="(date, index) in data.list" :key="index">
                            {{ formatDate(date) }}
                        </option>
                    </select>

                </div>
                <p class="mt-5">Master Invoice for {{ data.invoiceDate || data.selectDate }}</p>
            </div>
        </div>



        <div v-if="!data.loadData" class="d-flex align-items-end flex-column mb-3" style="height: 200px;">
            <div class="p-2">
                <div><strong>Total Count:</strong> {{ data.count }}</div>
                <div><strong>Total Amount:</strong> $ {{ data.totalAmount }}</div>
            </div>
        </div>

        <div class="table-responsive" v-if="!data.loadData">
            <table class="table fs-5 text">
                <thead>
                    <tr>
                        <th scope="col">Franchise</th>
                        <th scope="col">Practice Group Owner</th>
                        <th scope="col">City</th>
                        <th scope="col">Count</th>
                        <th scope="col">Amount</th>

                    </tr>
                </thead>
                <tbody class="table-group-divider">
                    <tr v-for="(billing, index) in data.franchiseInvoiceSummaries" :key="index">
                        <th scope="row">
                            <router-link class="mx-1" :to="{
                                path: '/view-invoice/',
                                query: { date: formatDate(billing.calendarDate), franchise_ID: billing.franchise_ID }
                            }">
                                {{ billing.franchiseName }}
                            </router-link>
                        </th>
                        <td>{{ billing.ownerFirstName }} {{ billing.ownerLastName }}</td>
                        <td>{{ billing.city }}</td>
                        <td>{{ billing.clientCount }}</td>
                        <td>${{ billing.rateSubTotal }}</td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>

    <div v-if="data.currentBilling &&
        data.currentBilling.franchiseMembership &&
        data.currentBilling.franchiseMembership.length > 0 &&
        (data.currentBilling.franchiseMembership[0].isOwnerAdmin === false ||
            data.currentBilling.franchiseMembership[0].isOwner === false)">
        <franchiseInvoice :button="false"
            :calendarDate="formatDate(data.selectDate) || `${currentYear}-${currentMonth}-${currentDay}`"
            :franchiseInvoiceID="data.currentBilling.franchiseMembership[0]" v-if="data.currentBilling && data.currentBilling.franchiseMembership &&
                data.currentBilling.franchiseMembership.length > 0" />
    </div>
</template>
